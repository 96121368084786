import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as style from "../components/css/blogPost.module.scss"

const NotFoundPage = () => (
  <Layout>
    <Seo title="ページが見つかりませんでした" />
    <div id="pageNotFound" className={`${style.wrap}`} >
      <section class="pageNotFound">
        <h1>ページが見つかりませんでした</h1>
        <a href="/">ワンダウォールのトップへ戻る</a>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
