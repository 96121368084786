// extracted by mini-css-extract-plugin
export var categoryName = "blogPost-module--categoryName--1d860";
export var entryContent = "blogPost-module--entryContent--1a592";
export var entryHeader = "blogPost-module--entryHeader--3bf60";
export var entryMeta = "blogPost-module--entryMeta--105e3";
export var entryTitle = "blogPost-module--entryTitle--f5e60";
export var excerptCopy = "blogPost-module--excerptCopy--97dd8";
export var fb_iframe_widget_fluid = "blogPost-module--fb_iframe_widget_fluid--8cdeb";
export var featuredImage = "blogPost-module--featuredImage--dfe70";
export var featuredImageFrame = "blogPost-module--featuredImageFrame--9e5e2";
export var postedOn = "blogPost-module--postedOn--457ce";
export var primary = "blogPost-module--primary--a8f0e";
export var wrap = "blogPost-module--wrap--071cd";